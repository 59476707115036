<template>
  <v-card class="cw-tupas-container">
    <div
      v-if="cameFromBroker && getCurrentPage !== 'carLoanTerms'"
      class="cw-tupas--broker"
    >
      <v-stepper
        v-model="stepper"
        class="cw-tupas__stepper"
      >
        <v-stepper-header style="display: none;">
          <v-stepper-step :complete="stepper > 1" step="1" editable>
            <translate>
              Welcome
            </translate>
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step :complete="stepper > 2" step="2">
            <translate>
              Identify
            </translate>
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-toolbar
              v-if="mobile"
              id="cw-tupas__toolbar"
              dark
              class="primary"
            >
              <v-toolbar-title class="ml-6">
                <translate>
                  Welcome
                </translate>
              </v-toolbar-title>
            </v-toolbar>
            <div class="pa-6">
              <h1 class="text-h5">
                <translate
                  :translate-params="{appliedBrand: brand}"
                >
                  Welcome to %{ appliedBrand } loan service!
                </translate>
              </h1>
              <p class="mt-4">
                <translate
                  :translate-params="{appliedBrand: brand}"
                >
                  You have been redirected from the brokerage site to the %{ appliedBrand } loan
                  service.
                </translate>
              </p>
              <p>
                <translate>
                  In order to accept the loan agreement, we ask you to identify yourself with your
                  bank ID or mobile certificate. Once authenticated, you can accept your loan
                  agreement. Get the loan right away, if you need it!
                </translate>
              </p>
              <v-btn
                id="go-to-identification"
                class="ml-0 mr-0 mt-4 mb-0"
                color="primary"
                @click.native="
                  stepper = 2;
                  $eventLogger.clickEvent($event);
                "
              >
                <translate>
                  Identify
                </translate>
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <cw-tupas-buttons :route="route"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <div
      v-else
      class="cw-tupas--default"
    >
      <cw-tupas-buttons :route="route"/>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwTupasButtonContainer',

  components: {
    'cw-tupas-buttons': () => import('@shared/components/TupasButtons'),
  },

  props: {
    /**
     * Tupas data.
     */
    route: {
      type: String,
      default: () => '',
    },
  },

  data: () => ({
    stepper: 1,
    brand: '',
  }),

  computed: {
    ...mapGetters({
      mobile: 'getMobile',
      cameFromBroker: 'application/cameFromBroker',
      getCurrentPage: 'application/getCurrentPage',
      getBrand: 'application/getBrand',
    }),
  },

  created() {
    const brands = {
      saldo: 'Saldo.com',
      limiitti: 'Limiitti.fi',
      vippi: 'vippi.fi',
    };

    this.brand = brands[this.getBrand] || '';
  },
};
</script>

<style lang="scss" scoped>
  .cw-tupas-container .v-stepper {
    box-shadow: none;
  }
</style>
